<template>
  <div class="row" id="about-us">
    <div class="col-12 col-md-8">
      <img id="logo" src="~Images/logo_large.png" class="img-fluid" />
      <div class="row" id="section-1">
        <div class="col-12">
          <h1>ABOUT US</h1>
          <h3>Our Mission</h3>
          <p>
            Our mission is to provide personalized information to aid your
            search for the electric vehicle that best addresses your unique
            needs. We provide objective data, instructive information, and our
            own editorial recommendations to help you reach an informed and
            balanced decision when shopping for your next electric vehicle.
          </p>

          <p>
            Electric Driver began with a really simple idea: Your needs are more
            important than their name. It doesn’t make sense to start shopping
            for a car by selecting a year/make/model/series. Shopping for a car
            should be based on what’s in the car, not what’s in the car’s name.
          </p>
        </div>
      </div>
      <div class="row" id="section-2">
        <div class="col-12">
          <h3>Who We Are</h3>
          <p>
            Our company founders have been working in and around the automotive
            industry for decades, promoting vehicles and the automotive
            lifestyle at every level. We love cars. We think about them night
            and day, and we hope we can make your car search easier by providing
            our unique perspectives on the car-buying experience. We also know
            that the new frontier in the automotive industry is in electric
            vehicles, and we know lots of folks are nervous about buying a new
            or different EV. We want to give you the tools needed to make an
            informed decision in this new and exciting automotive space.
          </p>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-4" id="sidebar">
      <img src="~Images/electricity_quote.png" class="img-fluid" />
      <h4>Our Lightning</h4>
      <ul>
        <li>
          We provide recommendations for electric vehicles that best address
          your unique needs
        </li>
        <li>
          We help you understand the trade-offs and make an informed decision
          when researching EVs
        </li>
        <li>
          We tailor our research experience to address your unique needs during
          the decision-making process
        </li>
        <li>
          We provide information about the EV ecosystem, including in-home,
          solar, and outside charging, and explain how the ecosystem works with
          vehicles that you’re researching
        </li>
        <li>
          We help you reduce your environmental footprint and increase the
          sustainability of your household
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'About Us'
  }
};
</script>

<style lang="scss" scoped>
#logo {
  width: 500px;
  margin-left: -75px;
  margin-top: 50px;
}

#about-us {
  padding-left: 115px;
}

h1,
h4 {
  color: white;
}

#section-1 {
  margin-top: 30px;
  margin-bottom: 50px;
}

p {
  padding-right: 35px;
}
#sidebar {
  padding-top: 20px;
  min-height: 89vh;
  padding-right: 115px;
  background-color: $dark-bg-color;

  h4 {
    padding-left: 35px;
  }

  img {
    min-width: 300px;
  }
}

ul {
  li {
    padding-bottom: 25px;
    list-style-image: url("~Images/leaf_bullet.png");
  }
}
</style>